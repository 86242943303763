import React from 'react';
import { Link } from '@mui/material';
import styles from './Footer.module.scss';
import { useResponsiveMode } from '../../hooks/useResponsiveMode';

export default function Footer() {
  const { isMobile } = useResponsiveMode();
  return (
    <div className={styles.container}>
      <div>
        <Link className={styles.link}  href="https://emtrain.com/" target="_blank">
          &copy; {`${new Date().getFullYear()} Emtrain `}
        </Link>
        {!isMobile && '|'}
      </div>
      {isMobile &&
        <div className={styles.break} />
      }
      <div>
        <Link className={styles.link}  href="https://emtrain.com/emtrain-privacy-policy/"  target="_blank">
          Privacy Policy
        </Link>
      </div>
    </div >
  );
}
