import React, { useEffect } from "react";
import { Dialog, DialogContent } from "@mui/material";
import AddToPlaylist from "./AddToPlaylist";
import styles from "./CreatePlaylist.module.scss";
import { useSelector, useDispatch } from "react-redux";
import { getCreatePlaylist } from "../../actions/homeActions";
import { getAccessToken } from "../../../services/api/authentication";
import CreatePlaylist from "./CreatePlaylist";
import BootstrapDialogTitle from "../CloseButton";

const CreatePlaylistInModal = ({
  open,
  handleClose,
  userID,
  videoID,
  showPlaylist,
}) => {
  // console.log("------------CREATE PLAYLIST-----");
  const dispatch = useDispatch();
  const loginToken = getAccessToken();

  const playlists = useSelector((state) => state.homeReducerData.playlists);
  const createdPlaylist = useSelector(
    (state) => state.homeReducerData.createdPlaylist
  );
  // console.log(playlists, "playlists");
  //   useEffect(() => {
  //     dispatch(decodeJWTToken());
  //   }, []);

  useEffect(() => {
    userID && dispatch(getCreatePlaylist(userID));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userID, loginToken]);

  useEffect(() => {
    // console.log(createdPlaylist && userID,'createdPlaylist && userID')
    // debugger;
    createdPlaylist && userID && dispatch(getCreatePlaylist(userID));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createdPlaylist]);

  // console.log(playlist, "playlistplaylistplaylistplaylist");
  return (
    <div className={styles?.modal}>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={styles?.modal}
        sx={{
          ".MuiPaper-root": {
            width: "100%",
            maxWidth: "332px",
            borderRadius: "7px",
          },
          ".MuiFormControl-root": { width: "100%" },
        }}
      >
        {showPlaylist && (
          <BootstrapDialogTitle
            id="customized-dialog-title"
            className={styles?.heading}
            onClose={handleClose}
          >
            {"Add to Playlist"}
          </BootstrapDialogTitle>
        )}
        <DialogContent>
          {/* <DialogContentText id="alert-dialog-description"> */}
            {showPlaylist &&
              playlists?.data?.map((playlist) => {
                return (
                  <AddToPlaylist
                    key={playlist?.name}
                    playlist={playlist}
                    videoID={videoID}
                    userID={userID}
                  />
                );
              })}

          <CreatePlaylist
            userID={userID}
            videoID={videoID}
            handleClose={handleClose}
          />
          {/* </DialogContentText> */}
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default React.memo(CreatePlaylistInModal);
