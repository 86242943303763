import {
  GET_VIDEOS,
  DECODE_JWT_TOKEN_DATA,
  GET_CREATE_PLAYLIST,
  CREATE_PLAYLIST,
  ERROR_CREATE_PLAYLIST,
  REMOVE_VIDEO_FROM_CREATE_PLAYLIST,
  EDIT_PLAYLIST,
  DELETE_PLAYLIST,
  GET_VIDEO,
  REDORDER_PLAYLIST,
} from "../actions/actionConstants";

const initialState = {
  videos: [],
  decodedJwtData: [],
  playlists: [],
  errorCreatePlaylist: [],
  video: [],
};

export const homeReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_VIDEOS:
      return {
        ...state,
        videos: action.payload,
      };
    case DECODE_JWT_TOKEN_DATA:
      return {
        ...state,
        decodedJwtData: action.payload,
      };
    case GET_CREATE_PLAYLIST:
      return {
        ...state,
        playlists: action.payload,
        createdPlaylist: false,
      };
    case CREATE_PLAYLIST:
      // console.log(state.playlists, action.payload?.data, "-----", [
      //   ...state.playlists?.data,
      //   action?.payload?.data,
      // ]);
      // debugger;
      return {
        ...state,
        // playlists: { data: [...state.playlists?.data, action?.payload?.data] },
        createdPlaylist: true,
      };
    case ERROR_CREATE_PLAYLIST:
      return {
        ...state,
        errorCreatePlaylist: action.payload,
      };
    case REMOVE_VIDEO_FROM_CREATE_PLAYLIST:
      return {
        ...state,
        createdPlaylist: true,
      };
    case EDIT_PLAYLIST:
      return {
        ...state,
        createdPlaylist: true,
      };
    case DELETE_PLAYLIST:
      return {
        ...state,
        createdPlaylist: true,
      };
    case GET_VIDEO:
      return {
        ...state,
        video: action.payload,
      };
    case REDORDER_PLAYLIST:
      return {
        ...state,
        createdPlaylist: true,
      };
    default:
      return state;
  }
};
