import { Stack, TextField } from "@mui/material";
import styles from "./EditPlaylist.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPen,
  faTrash,
  faBars,
  faCircleCheck,
} from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { editPlaylist, deletePlaylist } from "../../actions/homeActions";

const EditPlaylistItem = ({ playlist, userID }) => {
  const dispatch = useDispatch();
  const [showEditPlaylist, setShowEditPlaylist] = useState(false);
  const [editplaylist, setEditPlaylist] = useState("");

  const handleShowEditPlaylist = () => {
    //   debugger;
    setShowEditPlaylist((value) => !value);
  };
  const handleSaveEditPlaylist = () => {
    const data = {
      name: editplaylist,
    };
    //   console.log(data,userID,playlist?.id,'data,userID,playlist?.id');
    // debugger;
    data?.name !== "" && dispatch(editPlaylist(data, userID, playlist?.id));
    setShowEditPlaylist((value) => !value);
  };
  const handleDeletePlaylist = () => {
    dispatch(deletePlaylist(userID, playlist?.id));
  };

  const handleChange = (event) => {
    setEditPlaylist(event.target.value);
  };
  return (
    <>
      <Stack
        direction="row"
        // justifyContent="space-between"
        alignItems="center"
        key={playlist?.id}
      >
        <div>
          <FontAwesomeIcon icon={faBars} className={styles.reorderIcon} />
        </div>
        {!showEditPlaylist && (
          <div className={styles.editField}>
            <div className={styles.name}>{playlist?.name}</div>
            <FontAwesomeIcon
              icon={faPen}
              className={styles.editIcon}
              onClick={handleShowEditPlaylist}
            />
          </div>
        )}
        {showEditPlaylist && (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            className={styles.editInputField}
          >
            <TextField
              // error={errorCreatePlaylist}
              // id="outlined-basic"
              defaultValue={playlist?.name}
              variant="outlined"
              placeholder="Name"
              onChange={handleChange}
              //   value={playlist?.name}
              // helperText="Incorrect entry."
              sx={{
                ".MuiInputBase-input": {
                  padding: "7px",
                  fontSize: "13px",
                  border: "0.2px solid",
                  borderColor: "rgba(176, 181, 210, 1)",
                },
                "&.MuiFormControl-root": {
                  width: "100%",
                },
              }}
              className={styles?.input}
            />
            <div>
              <FontAwesomeIcon
                icon={faCircleCheck}
                className={styles.editIcon}
                onClick={handleSaveEditPlaylist}
              />
            </div>
          </Stack>
        )}

        <div>
          <FontAwesomeIcon
            icon={faTrash}
            className={styles.deleteIcon}
            onClick={() => handleDeletePlaylist()}
          />
        </div>
      </Stack>
    </>
  );
};
export default EditPlaylistItem;
