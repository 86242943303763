
import * as React from 'react';
import { Chip } from "@mui/material"
import styles from "./ButtonTab.module.scss";

const ButtonTab = ({ label, onClick, selected }) => {
    return (
        <>
            <div className={styles.button}>
                <Chip sx={{
                    ".MuiChip-label": { paddingRight: "8px", paddingLeft: "8px" }
                }} className={`${styles.btn} ${selected ? styles.activeBtn : styles.disableBtn}`} label={label} onClick={onClick} />
            </div>
        </>
    )
}
export default ButtonTab