import { Grid, Stack } from "@mui/material";
import React from "react";
import styles from "./VideoCard.module.scss";
import VideoCard from "./VideoCard";
import BrowseIcons from "./BrowseIcons";
import { Link } from "react-router-dom";

const BrowseVideoCard = ({
  video,
  handleCreatePlaylist,
  handleVideoCardView,
  debug,
}) => {
  return (
    <>
      <div className={styles.card}>
        <Grid container className={styles.container}>
          <Grid
            item
            xs={12}
            sm={6}
            md={12}
            lg={12}
            xl={12}
            className={styles.thumbnailSection}
          >
            <Link to={`/video/${video?.csVideoId}`}>
              <div>
                <VideoCard video={video} />
              </div>
            </Link>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={12}
            lg={12}
            xl={12}
            className={styles.titleSection}
          >
            <div>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
              >
                <div className={styles.title}>{video?.title}</div>
                <Stack
                  direction="row"
                  alignItems="center"
                  className={styles.icon}
                >
                  <BrowseIcons
                    handleVideoCardView={handleVideoCardView}
                    handleCreatePlaylist={handleCreatePlaylist}
                    video={video}
                    debug={debug}
                    showAllIcons={true}
                  />
                </Stack>
              </Stack>
              <p className={styles.description}>{video?.description}</p>
            </div>
            <Stack
              direction="row"
              alignItems="center"
              className={`${styles.icon} ${styles.bottomIconSection}`}
            >
              <BrowseIcons
                handleVideoCardView={handleVideoCardView}
                handleCreatePlaylist={handleCreatePlaylist}
                video={video}
                debug={debug}
                showAllIcons={true}
              />
            </Stack>
          </Grid>
        </Grid>
      </div>
    </>
  );
};
export default BrowseVideoCard;
