import { combineReducers } from "redux";
import { homeReducer } from "./Home/reducers/homeReducer";
// import 
// import
// import 

// const Reducer = (state = initState, action) => {
//     return state;
// }

// export default Reducer
const rootReducer = combineReducers({
    homeReducerData: homeReducer,
});

export default rootReducer;