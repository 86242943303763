import { useTheme, useMediaQuery } from '@mui/material';

export function useResponsiveMode() {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('desktop'));
  const isLaptop = useMediaQuery(theme.breakpoints.between('laptop', 'desktop'));
  const isTablet = useMediaQuery(theme.breakpoints.between('tablet', 'laptop'));
  const isMobile = useMediaQuery(theme.breakpoints.down('tablet'));
  const isSmallMobile = useMediaQuery(theme.breakpoints.down('smallMobile'));

  return { isSmallMobile, isMobile, isTablet, isLaptop, isDesktop };
}
