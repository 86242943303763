import { Routes, Route } from "react-router-dom";
import "./App.css";
import Home from "./Home/container/Home";
import VideoPlayer from "./Home/components/VideoPlayer/VideoPlayer";


function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/video/:id" element={<VideoPlayer />} />
    </Routes>
  );
}

export default App;
