import { axiosClient } from "../../config/axios";

const JWT_STORAGE_KEY = 'ai-jwt';

export const getAccessToken = () => {
  const objUrlParams = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
  const accessTokenFromUrl = objUrlParams.token;
  return accessTokenFromUrl || null;
}

export const validateJwt = async ({ accessToken }) => {
  try {
    const result = await axiosClient.post('/authentication', {
      accessToken,
      strategy: 'jwt',
    });
    return result.data;
  } catch (error) {
    // console.error(error)
    return error;
  }
};

export const setLoginToken = (token) => {
  localStorage.setItem(JWT_STORAGE_KEY, token);
};

export const getLoginToken = () => {
  return localStorage.getItem(JWT_STORAGE_KEY) || null;
};

export const removeLoginToken = () => {
  localStorage.removeItem(JWT_STORAGE_KEY);
};
