import React, { useCallback, useState } from "react";
import { Stack } from "@mui/material";
import PlayListAccordion from "../components/PlayListAccordion/PlayListAccordion";
import CreatePlaylist from "../components/CreatePlaylist/CreatePlaylist";
import styles from "./Playlist.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus, faPen } from "@fortawesome/free-solid-svg-icons";
import CreatePlaylistInModal from "../components/CreatePlaylist/CreatePlaylistInModal";
import EditPlaylist from "../components/EditPlaylist/EditPlaylist";
import CardView from "../components/CardView/CardView";

const Playlist = ({ filterData, videoID, userID, showBrowseVideosTab }) => {
  const [openCreatePlaylist, setOpenCreatePlaylist] = useState(false);
  const [OpenEditPlaylist, setOpenEditPlaylist] = useState(false);
  const [openVideoCardView, setOpenVideoCardView] = useState(false);
  const [videoData, setVideoData] = useState([]);

  const handleCloseCreatePlaylist = useCallback(() => {
    setOpenCreatePlaylist((value) => !value);
  }, []);

  const handleEditPlaylist = () => {
    setOpenEditPlaylist((value) => !value);
  };

  // const handleVideoCardView = () => {
  //   setOpenVideoCardView(true);
  // };

  const handleCloseVideoCardView = () => {
    setOpenVideoCardView(false);
  };
  const handleOpenVideoCard = (data) => {
    setVideoData(data);
    setOpenVideoCardView(true);
  };

  return (
    <div className={styles.playlist}>
      <div>
        <Stack direction="row" justifyContent="end" alignItems="center">
          <FontAwesomeIcon
            icon={faCirclePlus}
            className={styles.circlePlusIcon}
            onClick={handleCloseCreatePlaylist}
          />
          <FontAwesomeIcon
            icon={faPen}
            className={styles.penIcon}
            onClick={handleEditPlaylist}
          />
        </Stack>
        {filterData?.map((filter) => {
          return (
            <PlayListAccordion
              key={filter?.id}
              filter={filter}
              showBrowseVideosTab={showBrowseVideosTab}
              handleOpenVideoCard={handleOpenVideoCard}
              userID={userID}
            />
          );
        })}
      </div>
      {/* {console.log(userID, "userID--")} */}
      {filterData?.length === 0 && (
        <div className={styles.createPlaylist}>
          <CreatePlaylist userID={userID} videoID="" />
        </div>
      )}
      {/* {filterData?.length === 1 && filterData[0]?.webexVideos?.length === 0 && (
        <div className={styles.addVideosToPlaylist}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
              <div></div>
          </Stack>
        </div>
      )} */}

      <CreatePlaylistInModal
        open={openCreatePlaylist}
        handleClose={handleCloseCreatePlaylist}
        showPlaylist={false}
        userID={userID}
      />

      <EditPlaylist
        open={OpenEditPlaylist}
        handleClose={handleEditPlaylist}
        playlists={filterData}
        userID={userID}
      />
      <CardView
        open={openVideoCardView}
        handleClose={handleCloseVideoCardView}
        videoData={videoData}
      />
    </div>
  );
};

export default Playlist;
