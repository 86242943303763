import React, { useState } from "react";
import MuiAccordion from "@mui/material/Accordion";
import { styled } from "@mui/material/styles";

import {
  Stack,
  ClickAwayListener,
  Tooltip,
  tooltipClasses,
} from "@mui/material";

import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import styles from "./PlayListAccordion.module.scss";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLink,
  faTrash,
  faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import { ReactComponent as Play } from "../../../images/play.svg";
import { ReactComponent as PlayBg } from "../../../images/playBg.svg";

import { ReactComponent as SmallPlay } from "../../../images/smallplay.svg";
import { ReactComponent as SmallPlayBg } from "../../../images/smallPlayBg.svg";
import { deleteVideoFromPlaylist } from "../../actions/homeActions";
import { useDispatch } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Link } from "react-router-dom";

const PlayListAccordion = ({
  filter,
  showBrowseVideosTab,
  handleOpenVideoCard,
  userID,
  debug,
}) => {
  const dispatch = useDispatch();

  const [expanded, setExpanded] = React.useState(false);
  const [copied, setCopied] = useState(false);

  const [openTooltip, setOpenTooltip] = useState(false);

  const handleTooltipOpen = () => {
    setOpenTooltip(true);
  };

  const handleTooltipClose = () => {
    setOpenTooltip(false);
    console.log(copied);
  };

  const handleChange = (panel) => (event, newExpanded) => {
    // console.log(newExpanded, panel, newExpanded ? panel : false);
    setExpanded(newExpanded ? panel : false);
  };

  const handleDeleteVideoFromPlaylist = (videoId) => {
    // console.log(userID, filter?.id, videoId, "userID,filter?.id,videoId");
    // debugger;
    dispatch(deleteVideoFromPlaylist(userID, filter?.id, videoId));
  };
  const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
  }));

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 13,
      padding: 13,
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.white,
    },
  }));
  return (
    <>
      <Accordion
        className={styles.accordion}
        expanded={expanded === filter?.id}
        onChange={handleChange(filter?.id)}
        sx={{
          "&.MuiPaper-elevation": {
            backgroundColor: "rgba(199, 201, 221, 1)",
            height: "33px",
            borderRadius: "7px",
            margin: "10px 0px !important",
          },
          "&.Mui-expanded": { height: "auto" },
          ".Mui-expanded, .MuiCollapse-entered": {
            backgroundColor: "rgba(242, 240, 246, 1)",
            height: "auto",
            paddingTop: "3px",
          },
          ".MuiAccordionSummary-root": { minHeight: "0", height: "inherit" },
        }}
      >
        <AccordionSummary
          aria-controls={`panel${filter?.id}bh-header`}
          id={`panel${filter?.id}bh-header`}
          sx={{
            ".MuiAccordionSummary-content": {
              justifyContent: "space-between",
              margin: "0",
            },
          }}
        >
          <Typography
            className={styles.heading}
            sx={{
              flexShrink: 0,
              color: "#222442",
              fontSize: "14px",
              fontWeight: "650",
              fontFamily: "SourceSansPro-SemiBold",
            }}
          >
            {filter?.name}
          </Typography>
          <Typography
            sx={{
              color: "text.secondary",
              fontSize: "14px",
              fontWeight: "400",
              fontFamily: "SourceSansPro-Regular",
            }}
          >
            {filter?.webexVideos?.length} videos
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            ".MuiCollapse-entered": {
              backgroundColor: "rgba(242, 240, 246, 1)",
            },
          }}
        >
          {filter?.webexVideos?.map((webexVideo) => {
            return (
              <Stack
                direction="row"
                spacing={2}
                className={styles?.filterVideo}
                key={webexVideo?.id}
              >
                <Link to={`/video/${webexVideo?.csVideoId}`}>
                  <div
                    style={{ position: "relative" }}
                    className={styles.thumbnailSection}
                  >
                    <img
                      src={webexVideo?.thumbnail}
                      alt={webexVideo?.title}
                      className={styles.thumbnail}
                    ></img>
                    <div className={styles.onthumbnailPlay}>
                      <Play className={styles.play} />
                      <PlayBg className={styles.playBg} />
                    </div>
                    <div className={styles.onthumbnailSmallPlay}>
                      <SmallPlay className={styles.play} />
                      <SmallPlayBg className={styles.playBg} />
                    </div>
                  </div>
                </Link>

                <Stack
                  direction="row"
                  justifyContent="space-between"
                  sx={{ width: "100%" }}
                >
                  <div className={styles.titile}>{webexVideo?.title}</div>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    className={styles.icon}
                  >
                    <InfoOutlinedIcon
                      className={styles.infoIcon}
                      onClick={() => handleOpenVideoCard(webexVideo)}
                    />
                    <ClickAwayListener onClickAway={handleTooltipClose}>
                      <div>
                        <LightTooltip
                          arrow
                          // placement="top"
                          PopperProps={{
                            disablePortal: true,
                          }}
                          onClose={handleTooltipClose}
                          open={openTooltip}
                          // disableFocusListener
                          // disableHoverListener
                          // disableTouchListener
                          title="Link copied!"
                        >
                          {/* <Button onClick={handleTooltipOpen}>Click</Button> */}
                          <section
                            className="section"
                            style={{ display: "flex" }}
                            onClick={handleTooltipOpen}
                          >
                            <CopyToClipboard
                              options={{ debug: debug, message: "" }}
                              onCopy={() => setCopied(true)}
                              text={webexVideo?.emtrainUrl}
                            >
                              {/* <FontAwesomeIcon
                                icon={faLink}
                                onClick={() => setCopied(true)}
                              /> */}
                              <FontAwesomeIcon
                                icon={faLink}
                                className={styles.fontIcon}
                                onClick={() => setCopied(true)}
                              />
                            </CopyToClipboard>
                          </section>
                        </LightTooltip>
                      </div>
                    </ClickAwayListener>

                    <FontAwesomeIcon
                      icon={faTrash}
                      className={styles.fontIcon}
                      onClick={() =>
                        handleDeleteVideoFromPlaylist(webexVideo?.id)
                      }
                    />
                  </Stack>
                </Stack>
              </Stack>
            );
          })}
          {filter?.webexVideos?.length === 0 && (
            <div
              className={styles.addToPlaylistt}
              onClick={showBrowseVideosTab}
            >
              <FontAwesomeIcon
                icon={faMagnifyingGlass}
                className={styles.searchIcon}
              />
              <div>Browse videos to add to playlist</div>
            </div>
          )}
        </AccordionDetails>
      </Accordion>
    </>
  );
};
export default PlayListAccordion;
