import { Dialog, DialogContent } from "@mui/material";
import styles from "./EditPlaylist.module.scss";
import EditPlaylistItem from "./EditPlaylistItem";
import BootstrapDialogTitle from "../CloseButton";
import { Draggable } from "react-drag-reorder";
import { useDispatch } from "react-redux";
import { reorderPlaylist } from "../../actions/homeActions";

const EditPlaylist = ({ open, handleClose, playlists, userID }) => {
  const dispatch = useDispatch();
  const getChangedPos = (currentPos, newPos) => {
    // Delete the item from it's current position
    var playlist = playlists.splice(currentPos, 1);

    // Move the item to its new position
    playlists.splice(newPos, 0, playlist[0]);

    const playlistArray = playlists?.map((playlist) => {
      return playlist?.id;
    });
    const data = { playlistIds: playlistArray };
    console.log(playlists, data);
    dispatch(reorderPlaylist(userID, data));
    console.log(currentPos, newPos);
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={styles?.editPlaylist}
      sx={{
        ".MuiPaper-root": {
          width: "100%",
          maxWidth: "332px",
          borderRadius: "7px",
          margin: "28px",
        },
        ".MuiFormControl-root": { width: "100%" },
      }}
    >
      <BootstrapDialogTitle
        id="customized-dialog-title"
        className={styles?.heading}
        onClose={handleClose}
      >
        {"Edit Playlists"}
      </BootstrapDialogTitle>

      <DialogContent className={styles?.content}>
        <Draggable onPosChange={getChangedPos}>
          {playlists?.map((playlist) => {
            return (
              <EditPlaylistItem
                key={playlist?.id}
                playlist={playlist}
                userID={userID}
              />
            );
          })}
        </Draggable>
      </DialogContent>
    </Dialog>
  );
};

export default EditPlaylist;
