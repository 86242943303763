export const GET_VIDEOS = "GET_VIDEOS";
export const DECODE_JWT_TOKEN_DATA = "DECODE_JWT_TOKEN_DATA";
export const CREATE_PLAYLIST = "CREATE_PLAYLIST";
export const GET_CREATE_PLAYLIST = "GET_CREATE_PLAYLIST";
export const ERROR_CREATE_PLAYLIST = "ERROR_CREATE_PLAYLIST";
export const REMOVE_VIDEO_FROM_CREATE_PLAYLIST = "REMOVE_VIDEO_FROM_CREATE_PLAYLIST";
export const ERROR_EDIT_PLAYLIST = "ERROR_EDIT_PLAYLIST";
export const EDIT_PLAYLIST = "EDIT_PLAYLIST";
export const DELETE_PLAYLIST = "DELETE_PLAYLIST";
export const GET_VIDEO = "GET_VIDEO";
export const REDORDER_PLAYLIST = "REDORDER_PLAYLIST";




