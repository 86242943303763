import {  apiURL } from "../../config/constants";
import { doHttpCall } from "../../config/axios";
import * as allActions from "./actionConstants";
import jwt_decode from "jwt-decode";
import { getAccessToken, getLoginToken } from "../../services/api/authentication";

export const getVideos =
  (filter = "") =>
  async (dispatch) => {
    // console.log(filter,'filter');
    const filterName = filter !== "" ? `&pillar=${filter}` : "";
    const payload = {
      method: "GET",
      url: apiURL() + `/meeting/videos?$limit=30${filterName}`,
    };
    try {
      const response = await doHttpCall(payload);
      if (response && response.status === 200) {
        dispatch({
          type: allActions.GET_VIDEOS,
          payload: { data: response.data },
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

export const decodeJWTToken = () => async (dispatch) => {
  let accessToken = getAccessToken() || getLoginToken();
  const decodeData = jwt_decode(accessToken);
  if (decodeData) {
    dispatch({
      type: allActions.DECODE_JWT_TOKEN_DATA,
      payload: { data: decodeData },
    });
  }
};

export const createPlaylist = (data, userID) => async (dispatch) => {
  const payload = {
    method: "POST",
    url: apiURL() + `/meeting/${userID}/playlist`,
    data: data,
  };
  // debugger;
  try {
    const response = await doHttpCall(payload);
    // const response = {
    //   status: 200,
    // };
    if (response && response.status === 200) {
      dispatch({
        type: allActions.CREATE_PLAYLIST,
        payload: { data: data },
      });
    }
    if (response && response.status === 400) {
      dispatch({
        type: allActions.ERROR_CREATE_PLAYLIST,
        payload: { data: response },
      });
    }
  } catch (e) {
    console.log(e);
  }
};

export const getCreatePlaylist = (userID) => async (dispatch) => {
  const payload = {
    method: "GET",
    url: apiURL() + `/meeting/${userID}/playlist`,
  };
  try {
    const response = await doHttpCall(payload);
    if (response && response.status === 200) {
      dispatch({
        type: allActions.GET_CREATE_PLAYLIST,
        payload: { data: response.data },
      });
    }
  } catch (e) {
    console.log(e);
  }
};

export const deleteVideoFromPlaylist =
  (userID, playerID, videoID) => async (dispatch) => {
    const payload = {
      method: "DELETE",
      url: apiURL() + `/meeting/${userID}/playlist/${playerID}/${videoID}`,
    };

    try {
      const response = await doHttpCall(payload);
      if (response && response.status === 200) {
        dispatch({
          type: allActions.REMOVE_VIDEO_FROM_CREATE_PLAYLIST,
          payload: { data: response.data },
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  // http://emtrain.localhost:3030/meeting/17/playlist/2

  export const editPlaylist = (data, userID, playlistID) => async (dispatch) => {
    const payload = {
      method: "PATCH",
      url: apiURL() + `/meeting/${userID}/playlist/${playlistID}`,
      data: data,
    };
    // debugger;
    try {
      const response = await doHttpCall(payload);
      // const response = {
      //   status: 200,
      // };
      if (response && response.status === 200) {
        dispatch({
          type: allActions.EDIT_PLAYLIST,
          payload: { data: data },
        });
      }
      if (response && response.status === 400) {
        dispatch({
          type: allActions.ERROR_EDIT_PLAYLIST,
          payload: { data: response },
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  export const reorderPlaylist = (userID, playlistIDs) => async (dispatch) => {
    const payload = {
      method: "PATCH",
      url: apiURL() + `/meeting/${userID}/playlist`,
      data: playlistIDs,
    };
    try {
      const response = await doHttpCall(payload);
      // const response = {
      //   status: 200,
      // };
      if (response && response.status === 200) {
        dispatch({
          type: allActions.REDORDER_PLAYLIST,
          payload: { data: playlistIDs },
        });
      }
      if (response && response.status === 400) {
        dispatch({
          type: allActions.ERROR_EDIT_PLAYLIST,
          payload: { data: response },
        });
      }
    } catch (e) {
      console.log(e);
    }
  };





  export const deletePlaylist =
  (userID, playlistID) => async (dispatch) => {
    const payload = {
      method: "DELETE",
      url: apiURL() + `/meeting/${userID}/playlist/${playlistID}`,
    };

    try {
      const response = await doHttpCall(payload);
      if (response && response.status === 200) {
        dispatch({
          type: allActions.DELETE_PLAYLIST,
          payload: { data: response.data },
        });
      }
    } catch (e) {
      console.log(e);
    }
  };
  const formattedsubs = (subtitles, videoId) => subtitles.map((sub) => {
    const url = apiURL(true);
    const subTitleUrl = `${url}/videos/${videoId}/subtitles/${sub.csSubtitleId}.vtt`;
    return {
      file: subTitleUrl,
      label: sub.languageProperName,
      kind: 'subtitles',
    };
  });

  export const getVideo = (videoID) => async (dispatch) => {
    const payload = {
      method: "GET",
      url: apiURL() + `/videos/${videoID}`
    };
    try{
      const response = await doHttpCall(payload, true);

      const videoInfo = {
        videoLoaded: true,
        playlist: [{
          file: response.data.cdnUrl,
          image: response.data.ssFilename
            && `https://s3.amazonaws.com/emtrain-video-content/${videoID}/original/${response.data.ssFilename}`,
          tracks: formattedsubs(response.data.subtitles, videoID),
        }],
      }
      if (response && response.status === 200) {
        dispatch({
          type: allActions.GET_VIDEO,
          payload: { data: videoInfo },
        });
      }
    }catch(e){
      console.log(e)
    }
  }
