import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Stack, Box, Tab, Grid, Container } from "@mui/material";
import { ReactComponent as Logo } from "../../images/logo.svg";
import ButtonTab from "../components/ButtonTab/ButtonTab";
import styles from "./Home.module.scss";
import { TabPanel } from "@mui/lab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import BrowseVideoCard from "../components/VideoCard/BrowseVideoCard";

import Playlist from "./Playlist";
import CreatePlaylistInModal from "../components/CreatePlaylist/CreatePlaylistInModal";
import CardView from "../components/CardView/CardView";

import { getVideos, decodeJWTToken } from "../actions/homeActions";
import Footer from "../../features/footer/Footer";

const Home = () => {
  const [value, setValue] = useState("1");
  const [showTab, setShowTab] = useState(true);
  const [openCreatePlaylist, setOpenCreatePlaylist] = useState(false);
  const [openVideoCardView, setOpenVideoCardView] = useState(false);
  const [videoID, setVideoID] = useState("");
  const [videoData, setVideoData] = useState([]);

  const dispatch = useDispatch();

  const videos = useSelector((state) => state.homeReducerData.videos);
  const decodedJwtData = useSelector(
    (state) => state.homeReducerData.decodedJwtData
  );
  const playlists = useSelector((state) => state.homeReducerData.playlists);
  // console.log(decodedJwtData, "decodedJwtData");

  const handleChange = useCallback((event, newValue) => {
    setValue(newValue);
  }, []);

  useEffect(() => {
    // console.log(value, "value");
  }, [value]);

  // console.log(videoUrl, "videoUrl");

  const handleCreatePlaylist = (id) => {
    setOpenCreatePlaylist(true);
    setVideoID(id);
  };

  const handleCloseCreatePlaylist = useCallback(() => {
    setOpenCreatePlaylist(false);
  }, []);

  const handleVideoCardView = (video) => {
    setVideoData(video);
    setOpenVideoCardView(true);
  };

  const handleCloseVideoCardView = () => {
    setOpenVideoCardView(false);
  };

  useEffect(() => {
    dispatch(getVideos());
    !decodedJwtData?.length && dispatch(decodeJWTToken());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFilterVideos = (filter = "") => {
    dispatch(getVideos(filter));
  };

  const showBrowseVideosTab = () => {
    setShowTab(true);
  };

  return (
    <div className={styles.home}>
      <Container maxWidth="md">
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={1}
          className={styles.logo}
        >
          <Logo style={{ height: 22, width: 91 }} />
        </Stack>
        <Stack direction="row" spacing={1}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <ButtonTab
                label={<Box sx={{ overflow: "visible" }}>Browse Videos</Box>}
                onClick={() => setShowTab(true)}
                selected={showTab}
              />
            </Grid>
            <Grid item xs={6}>
              <ButtonTab
                label={<Box sx={{ overflow: "visible" }}>My Playlists</Box>}
                onClick={() => setShowTab(false)}
                selected={!showTab}
              />
            </Grid>
          </Grid>
        </Stack>

        {showTab && (
          <Box
            sx={{
              width: "100%",
              ".MuiBox-root": {
                borderBottom: "unset",
              },
            }}
          >
            <TabContext value={value}>
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                  paddingTop: "10px",
                }}
              >
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                  sx={{
                    ".MuiTabs-flexContainer": {
                      justifyContent: "space-between",
                    },
                    ".MuiTabs-indicator": { backgroundColor: "#ffffff" },
                  }}
                >
                  <Tab
                    sx={{
                      width: "32%",
                      "&.MuiTab-textColorPrimary": {
                        color: "#ffffff40",
                        borderBottom: "1px solid #ffffff40",
                        fontSize: "12px",
                        textTransform: "capitalize",
                      },
                    }}
                    className={styles.tab}
                    onClick={() => handleFilterVideos()}
                    label="All Topics"
                    value="1"
                  />
                  <Tab
                    sx={{
                      width: "32%",
                      "&.MuiTab-textColorPrimary": {
                        color: "#ffffff40",
                        borderBottom: "1px solid #ffffff40",
                        fontSize: "12px",
                        textTransform: "capitalize",
                      },
                    }}
                    className={styles.tab}
                    label="Respect"
                    value="2"
                    onClick={() => handleFilterVideos("respect")}
                  />
                  <Tab
                    sx={{
                      width: "32%",
                      "&.MuiTab-textColorPrimary": {
                        color: "#ffffff40",
                        borderBottom: "1px solid #ffffff40",
                        fontSize: "12px",
                        textTransform: "capitalize",
                      },
                    }}
                    className={styles.tab}
                    label="Inclusion"
                    value="3"
                    onClick={() => handleFilterVideos("Inclusion")}
                  />
                </TabList>
              </Box>
              <TabPanel
                sx={{
                  padding: "24px 0px",
                }}
                value="1"
              >
                <Grid container spacing={2}>
                  {videos?.data?.data?.map((video) => {
                    return (
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={6}
                        lg={4}
                        xl={4}
                        key={video?.id}
                      >
                          <BrowseVideoCard
                            video={video}
                            handleCreatePlaylist={handleCreatePlaylist}
                            handleVideoCardView={handleVideoCardView}
                          />
                       
                      </Grid>
                    );
                  })}
                </Grid>
              </TabPanel>
              <TabPanel
                sx={{
                  padding: "24px 0px",
                  color: "#ffffff",
                }}
                value="2"
              >
                <Grid container spacing={2}>
                  {videos?.data?.data?.map((video) => {
                    return (
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={6}
                        lg={4}
                        xl={4}
                        key={video?.id}
                      >
                        <BrowseVideoCard
                          video={video}
                          handleCreatePlaylist={handleCreatePlaylist}
                          handleVideoCardView={handleVideoCardView}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </TabPanel>
              <TabPanel
                sx={{
                  padding: "24px 0px",
                  color: "#ffffff",
                }}
                value="3"
              >
                <Grid container spacing={2}>
                  {videos?.data?.data?.map((video) => {
                    return (
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={6}
                        lg={4}
                        xl={4}
                        key={video?.id}
                      >
                        <BrowseVideoCard
                          video={video}
                          handleCreatePlaylist={handleCreatePlaylist}
                          handleVideoCardView={handleVideoCardView}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </TabPanel>
            </TabContext>
          </Box>
        )}

        <CreatePlaylistInModal
          open={openCreatePlaylist}
          handleClose={handleCloseCreatePlaylist}
          userID={decodedJwtData?.data?.userId}
          videoID={videoID}
          showPlaylist={true}
        />
        <CardView
          open={openVideoCardView}
          handleClose={handleCloseVideoCardView}
          videoData={videoData}
        />
        {!showTab && (
          <Playlist
            filterData={playlists?.data}
            userID={decodedJwtData?.data?.userId}
            videoID={videoID}
            showBrowseVideosTab={showBrowseVideosTab}
          />
        )}

        {/* <VideoPlayer
          open={open}
          videoUrl={videoUrl}
          handleClose={handleClose}
        /> */}
      </Container>
      <Footer />
    </div>
  );
};

export default Home;
