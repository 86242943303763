import {
  Switch,
  styled,
  Stack,
} from "@mui/material";
import { useDispatch } from "react-redux";
import styles from "./CreatePlaylist.module.scss";
import {
  deleteVideoFromPlaylist,
  createPlaylist,
} from "../../actions/homeActions";

const AddToPlaylist = ({ playlist, videoID, userID }) => {
  const dispatch = useDispatch();
  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#177ddc" : "rgba(85, 85, 114, 1)",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255,255,255,.35)"
          : "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
  }));
  const playlistChecked =
    playlist?.webexVideos?.filter((video) => video?.id === videoID)?.length > 0
      ? true
      : false;
  // console.log(playlist,playlist?.webexVideos?.filter((video) => video?.id === videoID),'playlist--------------');

  const handleToggle = (e) => {
    !e.target.checked &&
      dispatch(deleteVideoFromPlaylist(userID, playlist?.id, videoID));
    if (e.target.checked) {
      const data = {
        videoId: videoID,
        playlistId: playlist?.id,
        name: "",
      };
      dispatch(createPlaylist(data, userID));
    }
  };
  return (
    // <div className={styles?.playlistMain}>
    <Stack
      direction="row"
      alignItems="center"
      style={{ marginBottom: "12px" }}
      className={styles.playlist}
    >
      <AntSwitch
        defaultChecked={playlistChecked}
        inputProps={{ "aria-label": "ant design" }}
        onClick={handleToggle}
      />
      <div
        className={styles.playlistTitle}
        style={{
          fontSize: "15px",
          fontWeight: "400",
          fontFamily: "SourceSansPro-Regular",
          color: "#40405D",
          marginLeft: "7px",
        }}
      >
        {playlist?.name}
      </div>
    </Stack>
    // </div>
  );
};

export default AddToPlaylist;
