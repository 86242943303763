
import { ReactComponent as Play } from "../../../images/play.svg";
import { ReactComponent as PlayBg } from "../../../images/playBg.svg";
import styles from "./VideoCard.module.scss";

const VideoCard = ({video}) => {
    return(
        <>
            <img
              src={video?.thumbnail}
              alt={video?.title}
              className={styles.thumbnail}
            ></img>
            <Play className={styles.play} />
            <PlayBg className={styles.playBg} />
        </>
    )
}
export default VideoCard