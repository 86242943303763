import { useState, useEffect } from "react";
import { Dialog, AppBar, IconButton, Stack } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import styles from "../../container/Home.module.scss";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getVideo } from "../../actions/homeActions";
import { JW_LICENSE } from "../../../config/constants";
import ReactJWPlayer from "react-jw-player";

// Create a new Webex app instance
const webexapp = new window.Webex.Application();
const VideoPlayer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let { id } = useParams();
  const [open, setOpen] = useState(true);

  // const desktopPlayerKey = `player-desktop-${video?.csVideoId}-${video?.id}`;
  const desktopPlayerKey = `player-desktop-${id}`;

  const video = useSelector((state) => state.homeReducerData.video?.data);
  // Wait for onReady() promise to fulfill before using framework
  webexapp
    .onReady()
    .then(() => {
      console.log("App ready. Instance", webexapp);
    })
    .catch((errorcode) => {
      console.log("Error with App ready instance ");
    });

  const autoStartVideo = (playerKey) => {
    const videoPlayer = document.getElementById(playerKey);
    console.log(videoPlayer, "videoPlayer");
    if (videoPlayer) {
      videoPlayer.focus();
    }
  };

  const handleClose = () => {
    webexapp.clearShareUrl();
    console.log("Closing session windows");
    setOpen(false);
    navigate("/");
  };
  useEffect(() => {
    dispatch(getVideo(id));
    var url = "https://app-webex.emtrain.com/video/" + id;
    webexapp
      .setShareUrl(url, "", "Emtrain Webex App")
      .then(() => {
        console.log("Set share URL", url);
      })
      .catch((errorcode) => {
        console.log("Error setting the url ");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      className="videoPlayerModal"
      sx={{
        ".MuiDialog-paper": { backgroundColor: "#000000" },
      }}
      // TransitionComponent={Transition}
    >
      <div className={styles.modalContent}>
        <AppBar
          sx={{ position: "relative", backgroundColor: "#000000" }}
          color="primary"
        >
          {/* <Toolbar> */}
          <Stack direction="row" justifyContent="end" alignItems="center">
            <IconButton
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Stack>
          {/* </Toolbar> */}
        </AppBar>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          className={styles.videoPlayer}
        >
          {video?.videoLoaded && (
            // <ReactPlayer
            //   url={video?.videoLink}
            //   controls={true}
            //   // width={"100%"}
            //   // height={"100%"}
            //   playing={true}
            // />
            <ReactJWPlayer
              className={styles.videoSize}
              playerId={desktopPlayerKey}
              playerScript="/jwplayer_8_19/jwplayer.js"
              playlist={video?.playlist}
              licenseKey={JW_LICENSE}
              customProps={{ width: "100%", aspectratio: "16:9" }}
              // Add events here when we get to a proper implementation
              // for events in the learner app
              onPlay={() => {}}
              onPause={() => {}}
              onResume={() => {}}
              onAutoStart={() => autoStartVideo(desktopPlayerKey)}
              onOneHundredPercent={() => handleClose()}
              isAutoPlay
              key={desktopPlayerKey}
              enableFullscreen="false"
            />
          )}
        </Stack>
      </div>
    </Dialog>
  );
};

export default VideoPlayer;
