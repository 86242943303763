import axios from 'axios';
import { getLoginToken, getAccessToken } from '../services/api/authentication';
import { apiURL } from './constants';



export const axiosClient = axios.create({
  baseURL: apiURL(),
  headers: {
    'Content-Type': 'application/json',
  },
});

const setHeaders = (options) => {
  let accessToken = getLoginToken() || getAccessToken();
  let AuthHeaders = {};
  if (accessToken) {
    AuthHeaders = {
      Authorization: accessToken,
      "Content-Type": "application/json",
    };
  } else {
    AuthHeaders = { "Content-Type": "application/json" };
  }
  options.headers = Object.assign({}, AuthHeaders, options.headers || {});
  return options;
};

export const doHttpCall = async (options, bypassauth = false) => {
  if (bypassauth === true) {
    options.headers = Object.assign({}, { "Content-Type": "application/json" }, options.headers || {});
  } else {
    options = setHeaders(options);
  }
  return await axios(options);
};
