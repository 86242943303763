import React, { useState } from "react";
import { TextField, Chip, Stack } from "@mui/material";
import styles from "./CreatePlaylist.module.scss";
import { useDispatch } from "react-redux";
import { createPlaylist } from "../../actions/homeActions";

const CreatePlaylist = ({ videoID, userID, handleClose }) => {
  const dispatch = useDispatch();

  const [playlist, setPlaylist] = useState("");

  const handleChange = (event) => {
    setPlaylist(event.target.value);
  };

  const handleCreatePlaylist = () => {
    const data = {
      videoId: videoID ? videoID : "",
      playlistId: "",
      name: playlist,
    };
    console.log(userID, "userID");
    // debugger;
    dispatch(createPlaylist(data, userID));
    setPlaylist("");
  };

  return (
    <>
      <div className={styles?.label}>Create New Playlist</div>
      {/* <BootstrapDialogTitle
        id="customized-dialog-title"
        className={styles?.label}
        onClose={handleClose}
      >
        {"Create New Playlist"}
      </BootstrapDialogTitle> */}
      <TextField
        // error={errorCreatePlaylist}
        // id="outlined-basic"
        variant="outlined"
        placeholder="Name"
        onChange={handleChange}
        value={playlist}
        // helperText="Incorrect entry."
        sx={{
          ".MuiInputBase-input": {
            padding: "7px",
            fontSize: "13px",
            border: "0.2px solid",
            borderColor: "rgba(176, 181, 210, 1)",
          },
          "&.MuiFormControl-root": {
            width: "100%",
          },
        }}
        className={styles?.input}
      />
      <Stack className={styles.footer} alignItems="end">
        <Chip
          label="Save"
          className={styles?.button}
          sx={{
            "&.MuiChip-root": {
              backgroundColor: "rgba(85, 85, 114, 1)",
              color: "#ffffff",
              cursor: "pointer",
              width: "55px",
            },
          }}
          onClick={handleCreatePlaylist}
          disabled={playlist === ""}
        />
      </Stack>
    </>
  );
};

export default CreatePlaylist;
