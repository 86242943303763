import React, { useState, useContext, createContext, useEffect } from "react";
import {
  setLoginToken,
  getAccessToken,
  validateJwt,
} from "../services/api/authentication";

const authContext = createContext();
const redirectUrl = "https://emtrain4webex.webex.emtrain.com/";

export const useAuth = () => {
  return useContext(authContext);
};

function useProvideAuth() {
  const [data, setData] = useState(null);
  const [validatingJwt, setValidatingJwt] = useState(true);

  async function handleTokenValidation({ accessToken }) {
    try {
      const res = await validateJwt({ accessToken });
      res.accessToken && setLoginToken(accessToken);

      if (!res.accessToken) {
        window.location.href = redirectUrl;
      }
      console.log("Total data", res);
      setData(res);
      setValidatingJwt(false);
    } catch (e) {
      // removeLoginToken();
      setValidatingJwt(false);
    }
  }

  useEffect(() => {
    // const accessToken = getLoginToken();
    const accessToken = getAccessToken();
    if (accessToken) {
      handleTokenValidation({ accessToken });
    } else {
      setValidatingJwt(false);
      // window.location.href = redirectUrl;
    }
  }, []);

  return {
    data,
    validatingJwt,
  };
}

export function AuthProvider({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}
