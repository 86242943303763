
export const apiURL = () => {
  const subdomains = window.location.hostname.split('.');
  let apiHostName = '';
  if (subdomains.includes('localhost')) {
    // Developers use the port for the server API instead of a separate URL
    //  apiHostName = `${window.location.hostname}:3030`;
    apiHostName = 'emtrain.localhost:3030';
  } else {
    if (subdomains.includes('app-webex')) {
      apiHostName = 'emtrain4webex.webex-api.emtrain.com';
    }
  }
  return `${window.location.protocol}//${apiHostName}`;
};

export const JW_LICENSE = 'Hq6I/vn2cBnC57VaaUST07bWFgPNG6K2ZjZRCesGgxq+pwFU';
