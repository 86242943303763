import { Dialog, DialogContent, Link } from "@mui/material";
import styles from "./CardView.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import BootstrapDialogTitle from "../CloseButton";

const CardView = ({ open, handleClose, videoData }) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={styles?.cardView}
      sx={{
        ".MuiPaper-root": {
          width: "100%",
          maxWidth: "332px",
          borderRadius: "7px",
          margin: "28px",
        },
        ".MuiFormControl-root": { width: "100%" },
      }}
    >
      {/* <DialogTitle id="alert-dialog-title" className={styles?.heading}>
        {videoData?.title}
      </DialogTitle> */}
      <BootstrapDialogTitle
        id="customized-dialog-title"
        className={styles?.heading}
        onClose={handleClose}
      >
        {videoData?.title}
      </BootstrapDialogTitle>
      <DialogContent className={styles?.content}>
        {/* <DialogContentText id="alert-dialog-description"> */}
        <div className={styles?.paragraph}>{videoData?.description}</div>
        <div className={styles?.topicSection}>
          <div className={styles?.topic}>Emtrain Training Course</div>
          <div className={styles?.para}>
            {videoData?.lessonName}
            <Link
              href={videoData?.lessonLink}
              underline="none"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon
                icon={faUpRightFromSquare}
                className={styles.linkIcon}
              />
            </Link>
          </div>
        </div>
        <div className={styles?.topicSection}>
          <div className={styles?.topic}>Topic</div>
          <div className={styles?.para}>{videoData?.topic}</div>
        </div>
        {/* </DialogContentText> */}
      </DialogContent>

      {/* <DialogActions>
                    <Button onClick={handleClose}>Disagree</Button>
                    <Button onClick={handleClose} autoFocus>
                        Agree
                    </Button>
                </DialogActions> */}
    </Dialog>
  );
};

export default CardView;
