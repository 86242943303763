import {
  ClickAwayListener,
  Tooltip,
  styled,
  tooltipClasses,
} from "@mui/material";
import React, { useState } from "react";
import styles from "./VideoCard.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList, faLink } from "@fortawesome/free-solid-svg-icons";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { CopyToClipboard } from "react-copy-to-clipboard";

const BrowseIcons = ({
  handleVideoCardView,
  handleCreatePlaylist,
  video,
  debug,
  showAllIcons,
}) => {
  const value = video?.emtrainUrl;

  const [open, setOpen] = useState(false);
  const [copied, setCopied] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
    console.log(copied);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };


  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 13,
      padding: 13,
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.white,
    },
  }));

  return (
    <>
      {showAllIcons && (
        <>
          <InfoOutlinedIcon
            className={styles.infoIcon}
            onClick={() => handleVideoCardView(video)}
          />
          <FontAwesomeIcon
            icon={faList}
            onClick={() => handleCreatePlaylist(video?.id)}
          />
        </>
      )}

      <ClickAwayListener onClickAway={handleTooltipClose}>
        <div>
          <LightTooltip
            arrow
            // placement="top"
            PopperProps={{
              disablePortal: true,
            }}
            onClose={handleTooltipClose}
            open={open}
            // disableFocusListener
            // disableHoverListener
            // disableTouchListener
            title="Link copied!"
          >
            {/* <Button onClick={handleTooltipOpen}>Click</Button> */}
            <section className="section" onClick={handleTooltipOpen}>
              <CopyToClipboard
                options={{ debug: debug, message: "" }}
                onCopy={() => setCopied(true)}
                text={value}
              >
                <FontAwesomeIcon
                  icon={faLink}
                  onClick={() => setCopied(true)}
                />
              </CopyToClipboard>
            </section>
          </LightTooltip>
        </div>
      </ClickAwayListener>
    </>
  );
};
export default BrowseIcons;
